import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import DailyOverview from "@/views/DailyOverview";
import HealthWealth from "@/views/HealthWealth.vue";
import Vaccinations from "@/views/Vaccinations.vue";
import Stats from "@/views/Stats.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URK),
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/health-and-wealth",
      name: "health-and-wealth",
      component: HealthWealth,
    },
    {
      path: "/vaccinations",
      name: "vaccinations",
      component: Vaccinations,
    },
    {
      path: "/daily-overview",
      name: "dailyOverview",
      component: DailyOverview,
    },
    {
      path: "/stats",
      name: "stats",
      component: Stats,
    },
    {
      // regexp for catching all other values entered in the address bar
      // https://router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route
      path: "/:pathMatch(.*)*",
      redirect: { name: "home" },
    },
  ],
  scrollBehavior() {
    return { top: 0, behavior: "instant" }; // Always scroll to top
  },
});

export default router;
