<template>
  <div class="home mx-auto">
    <b-container class="container">
      <div class="content">
        <h1>Health Visuals</h1>
        <p>
          Welcome to Health Visuals! This project is my personal pursuit to
          transform health-related data into clear and engaging visual
          representations to enhance understanding. So far, I have been focusing
          on visualising Covid-19 data, but the plan is to expand the scope to
          include a variety of topics in global and public health. My goal is to
          make complex health information accessible and informative for
          everyone. Since most graphs are quite detailed, Health Visuals is best
          viewed on larger screens for optimal clarity.
        </p>
      </div>

      <div class="my-5">
        <b-card-group deck class="project-cards">
          <b-row>
            <b-col cols="12" md="4" class="mb-3">
              <RouterLink to="/health-and-wealth">
                <b-card title="Health and Wealth">
                  <b-card-body>
                    <p>
                      How do GDP per capita and wealth inequality affect the
                      health of nations?
                    </p>
                  </b-card-body>
                </b-card>
              </RouterLink>
            </b-col>

            <b-col cols="12" md="4" class="mb-3">
              <RouterLink to="/vaccinations">
                <b-card title="Vaccination Visualisation">
                  <b-card-body>
                    <p>
                      A detailed visual analysis of the impact of the Covid-19
                      vaccination in Germany
                    </p>
                  </b-card-body>
                </b-card>
              </RouterLink>
            </b-col>

            <b-col cols="12" md="4" class="mb-3">
              <RouterLink to="/daily-overview">
                <b-card
                  :title="`Daily Overview (${new Date().toLocaleDateString(
                    'de-DE'
                  )})`"
                >
                  <b-card-body>
                    <p>
                      Today's overview of the 7-Day incidence of Covid-19 in
                      Germany
                    </p>
                  </b-card-body>
                </b-card>
              </RouterLink>
            </b-col>

            <b-col cols="12" md="4" class="mb-3">
              <RouterLink to="/stats">
                <b-card title="Some Stats">
                  <b-card-body>
                    <p>Tools for performing statistical analysis</p>
                  </b-card-body>
                </b-card>
              </RouterLink>
            </b-col>

            <b-col cols="12" md="4" class="mb-3">
              <RouterLink to="">
                <b-card title="More to come...">
                  <b-card-body>
                    <p></p>
                  </b-card-body>
                </b-card>
              </RouterLink>
            </b-col>
          </b-row>
        </b-card-group>
      </div>

      <b-row class="align-items-center">
        <b-col cols="12" sm="3" md="2" class="mb-3 mb-sm-0">
          <img src="minodora.jpg" style="width: 100%; border-radius: 10px" />
        </b-col>
        <b-col cols="12" sm="9" md="10">
          <p class="mb-0">
            Equally, Health Visuals serves as my personal portfolio. My name is
            Minodora Cristescu, and I am a software developer based in Berlin.
            During my M.Sc. in Information Systems Management at the TU Berlin,
            I developed an interest in eHealth. I worked for two years in the
            nephrology department at the Charité hospital, where I also wrote my
            master's thesis. If you're interested in discussing digital health
            or would like to collaborate on a project, please feel free to
            connect with me on
            <a href="https://www.linkedin.com/in/minodora-cristescu/"
              >LinkedIn.</a
            >
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import api from "../apis/index.js";
import GermanyMap from "@/components/GermanyMap.vue";

export default {
  name: "App",
  components: { GermanyMap },
  data() {
    return {
      dataGermany: "",
      options: [],
      selectedOption: null,
    };
  },
  computed: {},
  async mounted() {
    try {
      // Fetch Germany data
      const responseGermany = await api.get("germany");
      this.dataGermany = responseGermany.data;

      // Fetch states data
      let response = await api.get("states");
      this.options = response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../assets/palette.scss";

.home {
  max-width: 1200px;

  .project-cards {
    p {
      color: rgb(70, 70, 70);
    }

    .card {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }

  :deep(.card-title) {
    color: $m_orange;
  }

  :deep(.card-deck) {
    a:hover {
      text-decoration: none;
    }
  }

  @media (min-width: 768px) {
    .card {
      height: 100%; /* Makes all cards of equal height */
    }
  }
}
</style>
