<template>
  <div class="vaccinations mx-auto">
    <b-container class="container">
      <b-row class="content mb-3">
        <h2 class="px-0">Covid-19 Vaccination Visual Analysis</h2>
        <b-col class="p-0">
          <p>
            Below, we conduct a detailed visual analysis of the impact of
            Covid-19 vaccination. All data is sourced from the
            <a href="https://api.corona-zahlen.org/docs/">
              Robert Koch-Institut API </a
            >. The analysis spans three years, from January 2021 - when the
            first vaccines were administered - through December 2023. To provide
            a broader context, we begin in late February 2020, when the first
            Covid-19 cases were recorded in Germany. This extended timeframe
            offers a comprehensive view of the vaccination campaign's progress
            and impact. In order to smooth out short-term fluctuations, such as
            low registration numbers on weekends and holidays followed by
            subsequent spikes, we have applied a 7-day moving average throughout
            the analysis.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col lg="7">
          <VacsChart v-if="vacsHistData" :vacsData="vacsHistData" />
          <PlaceholderChart v-else />
        </b-col>
        <b-col lg="5" class="d-flex align-items-center"
          ><p>{{ vaccinations.vacsNumbers }}</p>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col lg="7">
          <CasesTimeChart
            v-if="casesHistData && vacsHistData"
            :casesData="casesHistData"
            :vacsData="vacsHistData"
            color="#ff9c00"
            domainEnd="260000"
            yAxisTitle="Covid-19 Cases"
          />
          <PlaceholderChart v-else />
        </b-col>
        <b-col lg="5" class="d-flex align-items-center"
          ><p>{{ vaccinations.vacsCases }}</p>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col lg="7">
          <CasesTimeChart
            v-if="deathsHistData && vacsHistData"
            :casesData="deathsHistData"
            :vacsData="vacsHistData"
            domainEnd="1200"
            yAxisTitle="Deaths due to Covid-19"
          />
          <PlaceholderChart v-else />
        </b-col>
        <b-col lg="5" class="d-flex align-items-center"
          ><p>{{ vaccinations.vacsD }}</p>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col lg="7">
          <CasesTimeChart
            v-if="ratioDCHistData && vacsHistData"
            :casesData="ratioDCHistData"
            :vacsData="vacsHistData"
            :gradient="true"
            domainEnd="9"
            yAxisTitle="% of Cases resulting in Death"
          />
          <PlaceholderChart v-else />
        </b-col>
        <b-col lg="5" class="d-flex align-items-center"
          ><p>{{ vaccinations.vacsRatio }}</p>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col lg="7">
          <RatioVacsChart
            v-if="ratioVacData"
            :data="ratioVacData"
            color="#24F0BB"
            domainEnd="6"
          />
          <PlaceholderChart v-else />
        </b-col>
        <b-col lg="5" class="d-flex align-items-center"
          ><p>{{ vaccinations.vacsReg }}</p>
        </b-col>
      </b-row>
      <!-- <b-row class="mb-3">
        <b-col>
          <h4>Limitations</h4>
          <p>text</p>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <h4>Further Reading</h4>
          <p>text</p>
        </b-col>
      </b-row> -->
    </b-container>
  </div>
</template>

<script>
// import api from "../apis/index.js";
import CasesTimeChart from "../components/charts/CasesTimeChart.vue";
import RatioVacsChart from "../components/charts/RatioVacsChart.vue";
import VacsChart from "../components/charts/VacsChart.vue";
import PlaceholderChart from "../components/charts/PlaceholderChart.vue";
import vaccinations from "@/assets/texts/vaccinations.json";

import vacsData from "@/data/vaccinations/vacs.json";
import casesData from "@/data/vaccinations/cases.json";
import deathsData from "@/data/vaccinations/deaths.json";

export default {
  name: "Vaccinations",
  components: { CasesTimeChart, RatioVacsChart, VacsChart, PlaceholderChart },
  data() {
    return {
      casesHistData: null,
      deathsHistData: null,
      ratioDCHistData: null,
      vacsHistData: null,
      ratioVacData: null,
      vaccinations,
      vacsData,
      casesData,
      deathsData,
    };
  },
  async mounted() {
    try {
      // const responseCasesHist = await api.get("history/cases");
      this.casesHistData = this.casesData;

      // const responseDeathsHist = await api.get("history/deaths");
      this.deathsHistData = this.deathsData;

      // const responseVacsHist = await api.get("history/vacs");
      this.vacsHistData = this.vacsData;

      this.ratioDCHistData = this.combineArrays(
        this.casesHistData,
        this.deathsHistData,
        "date",
        "date"
      );

      const response = await fetch("vacsDCasesRatio.json");
      this.ratioVacData = await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  methods: {
    combineArrays(array1, array2, commonAttribute1, commonAttribute2) {
      return array1.map((item1) => {
        const matchingItem = array2.find(
          (item2) => item2[commonAttribute2] === item1[commonAttribute1]
        );

        // Merge objects if a match is found
        if (matchingItem)
          return {
            date: item1.date,
            cases:
              matchingItem.cases == 0
                ? 0
                : (matchingItem.cases / item1.cases) * 100,
          };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/palette.scss";

.vaccinations {
  max-width: $m_content-width-large;

  @media (min-width: 576px) {
    .container-sm,
    .container {
      max-width: none;
      padding: 10px 3%;
    }
  }

  p {
    margin-bottom: 0;
  }
}
</style>
