<template>
  <div class="mx-auto">
    <b-container class="container">
      <b-row class="content">
        <h2 class="px-0">Normal Distribution</h2>
        <b-col class="p-0">
          <p>
            A normal distribution, also known as a Gaussian distribution, is a
            continuous probability distribution that is symmetric around its
            mean. This type of distribution is common in many natural and
            health-related measurements, such as height, body mass index (BMI),
            and even certain laboratory test results. This distribution helps us
            understand how most people fall within a typical range, with only a
            few having extremely high or low values. By analysing these
            distributions, healthcare professionals can determine what ranges
            are considered "normal" and identify individuals who may be at risk
            for health problems.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-0">
        <b-col lg="9">
          <div class="chart" ref="chart"></div>
        </b-col>
        <b-col lg="3">
          <p class="info-text mt-4">
            Adjust these values to explore how different ranges affect the
            probability distribution.
          </p>
          <div class="input-area">
            <label>
              Mean:
              <input
                type="number"
                v-model="mean"
                @input="updateChart"
                class="me-4"
              />
            </label>
            <label>
              Standard Deviation:
              <input type="number" v-model="stdDev" @input="updateChart" />
            </label>

            <div class="smaller-larger mt-1">
              <input type="number" v-model="larger" class="me-2" />
              <p class="mb-1 range-text">&#60; x &#60;</p>
              <input type="number" v-model="smaller" class="ms-2" />
            </div>

            <button
              type="button"
              class="btn btn-outline-dark"
              @click="calculate"
            >
              Visualise
            </button>

            <p v-if="result" class="math mt-3">
              p = {{ parseFloat(result).toFixed(4) }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import * as d3 from "d3";
import normalD from "@/data/normalDistribution.json";

export default {
  name: "NormalDistributionChart",
  data() {
    return {
      mean: null, // Default mean
      stdDev: null, // Default standard deviation
      svg: null, // Reference to the SVG element
      line: null, // Reference to the line generator
      smaller: null,
      larger: null,
      x: null,
      y: null,
      result: null,
      normalD,
    };
  },
  mounted() {
    this.drawChart();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      // Get new dimensions
      let width = parseInt(d3.select(this.$refs.chart).style("width"));
      let height = parseInt(d3.select(this.$refs.chart).style("height"));

      // Update SVG attributes if necessary
      this.svg.attr("width", width).attr("height", height);
    },
    calculate() {
      let sdSmaller = 0;
      let sdLarger = 0;
      let resultSmaller = null;
      let resultLarger = null;
      // Calculate new data based on mean and standard deviation
      const normalDist = (x) =>
        (1 / Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * Math.pow(x, 2));

      const data = d3.range(-4, 4, 0.01).map((x) => ({ x, y: normalDist(x) }));

      if (this.smaller) {
        sdSmaller = (this.smaller - this.mean) / this.stdDev;
        sdSmaller = Math.round(sdSmaller * 100) / 100;
        console.log(sdSmaller);
        if (sdSmaller > 0) {
          if (sdSmaller > 4) resultSmaller = 1;
          else resultSmaller = this.normalD[parseFloat(sdSmaller).toFixed(2)];
        } else if (sdSmaller <= 0) {
          if (sdSmaller < -4) resultSmaller = 0;
          else {
            let sdPositive = -sdSmaller;
            resultSmaller = 1 - this.normalD[parseFloat(sdPositive).toFixed(2)];
          }
        }
      }
      if (this.larger) {
        sdLarger = (this.larger - this.mean) / this.stdDev;
        sdLarger = Math.round(sdLarger * 100) / 100;
        console.log(sdLarger);
        if (sdLarger > 0) {
          if (sdLarger > 4) resultLarger = 0;
          else resultLarger = 1 - this.normalD[parseFloat(sdLarger).toFixed(2)];
        } else if (sdLarger <= 0) {
          if (sdLarger < -4) resultLarger = 1;
          else {
            let sdPositive = -sdLarger;
            resultLarger = this.normalD[parseFloat(sdPositive).toFixed(2)];
          }
        }
      }

      if (this.smaller && !this.larger) {
        this.result = resultSmaller;
      } else if (!this.smaller && this.larger) {
        this.result = resultLarger;
      } else if (this.smaller && this.larger && this.larger < this.smaller) {
        console.log(resultSmaller);
        console.log(resultLarger);
        this.result = resultSmaller - (1 - resultLarger);
      } else if (this.smaller && this.larger && this.larger > this.smaller) {
        console.log(resultSmaller);
        console.log(resultLarger);
        this.result = resultSmaller + resultLarger;
      }
      console.log(this.result);

      // Update the shaded area
      this.svg.selectAll(".area-path").remove();
      const area = d3
        .area()
        .x((d) => this.x(d.x))
        .y0(this.y(0))
        .y1((d) => this.y(d.y));

      const appendAreaPath = (filterCondition) => {
        this.svg
          .append("path")
          .datum(data.filter(filterCondition))
          .attr("class", "area-path")
          .attr("fill", "#47edc1")
          .attr("fill-opacity", 0.5)
          .attr("d", area);
      };

      if (this.smaller && !this.larger) {
        appendAreaPath((d) => d.x <= sdSmaller);
      } else if (!this.smaller && this.larger) {
        appendAreaPath((d) => d.x >= sdLarger);
      } else if (this.smaller && this.larger) {
        if (this.larger < this.smaller) {
          appendAreaPath((d) => d.x >= sdLarger && d.x <= sdSmaller);
        } else {
          appendAreaPath((d) => d.x >= sdLarger);
          appendAreaPath((d) => d.x <= sdSmaller);
        }
      }

      this.svg.selectAll(".user-line, .user-label, .sd-label").remove();

      const createLine = (xPos) => {
        this.svg
          .append("line")
          .attr("class", "user-line")
          .attr("x1", this.x(xPos))
          .attr("x2", this.x(xPos))
          .attr("y1", this.y(0))
          .attr("y2", this.y(0.45))
          .attr("stroke", "#ff9c00")
          .attr("stroke-width", 2);
      };

      const createText = (xPos, yPos, className, text) => {
        this.svg
          .append("g")
          .append("text")
          .attr("class", className)
          .attr("text-anchor", "middle")
          .attr("x", this.x(xPos))
          .attr("y", yPos)
          .attr("font-size", "16px")
          .attr("font-weight", "500")
          .attr("fill", "#ff9c00")
          .text(text);
      };

      if (sdSmaller) {
        createLine(sdSmaller);
        createText(sdSmaller, 30, "user-label", this.smaller);
        createText(sdSmaller, 350, "sd-label", sdSmaller);
      }

      if (sdLarger) {
        createLine(sdLarger);
        createText(sdLarger, 30, "user-label", this.larger);
        createText(sdLarger, 350, "sd-label", sdLarger);
      }
    },
    drawChart() {
      // set the dimensions and margins of the graph
      const margin = { top: 10, right: 15, bottom: 30, left: 15 };
      const width = 750 - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;

      const that = this;

      // Data for the normal distribution
      const mean = 0;
      const stdDev = 1;
      this.x = d3
        .scaleLinear()
        .domain([-4, 4])
        .range([margin.left, width - margin.right]);
      this.y = d3
        .scaleLinear()
        .domain([0, 0.5])
        .range([height - margin.bottom, margin.top]);

      // Normal distribution function
      const normalDist = (x) =>
        (1 / (stdDev * Math.sqrt(2 * Math.PI))) *
        Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));

      const data = d3.range(-4, 4, 0.1).map((x) => ({ x, y: normalDist(x) }));

      const line = d3
        .line()
        .x((d) => that.x(d.x))
        .y((d) => that.y(d.y));

      // append the svg object to the body of the page
      this.svg = d3
        .select(this.$refs.chart)
        .append("svg")
        .attr("viewBox", `0 0 750 400`) // the larger the last two numbers, the smaller the font size
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Add the line for the normal distribution
      this.svg
        .append("path")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", "#1237a1")
        .attr("stroke-width", 2)
        .attr("d", line);

      // Add the vertical line for standard deviation = 0
      this.svg
        .append("line")
        .attr("x1", that.x(0))
        .attr("x2", that.x(0))
        .attr("y1", that.y(0))
        .attr("y2", that.y(0.45))
        .attr("stroke", "black")
        .attr("stroke-width", 2);

      this.svg
        .append("g")
        .append("text")
        .attr("text-anchor", "middle")
        .attr("x", 360)
        .attr("y", 375)
        .attr("font-size", "16px")
        .attr("font-weight", "500")
        .text("⟵ Standard Deviation ⟶");

      // Add mean label:
      this.svg
        .append("g")
        .append("text")
        .attr("class", "mean-label")
        .attr("text-anchor", "middle")
        .attr("x", 360)
        .attr("y", 30)
        .attr("font-size", "16px")
        .attr("font-weight", "500");

      const createLine = (xPos) => {
        this.svg
          .append("line")
          .attr("class", "sd-line")
          .attr("x1", this.x(xPos))
          .attr("x2", this.x(xPos))
          .attr("y1", this.y(0))
          .attr("y2", this.y(0.45))
          .attr("stroke", "#696969")
          .attr("stroke-opacity", 0)
          .attr("stroke-width", 1)
          .attr("stroke-dasharray", "4");
      };

      const createLabel = (xPos, className) => {
        this.svg
          .append("g")
          .append("text")
          .attr("class", className)
          .attr("text-anchor", "middle")
          .attr("x", xPos)
          .attr("y", 40)
          .attr("font-size", "12px")
          .attr("font-weight", "500")
          .attr("fill", "#696969");
      };

      // Add vertical lines for standard deviation at x positions 1, 2, -1, -2
      [1, 2, -1, -2].forEach(createLine);

      // Add SD labels at specified x positions
      const labelPositions = [
        { x: 445, class: "sd-label-1" },
        { x: 535, class: "sd-label-2" },
        { x: 275, class: "sd-label-1n" },
        { x: 190, class: "sd-label-2n" },
      ];

      labelPositions.forEach(({ x, class: className }) =>
        createLabel(x, className)
      );

      // Add axes
      const xAxis = d3.axisBottom(that.x).ticks(10);

      this.svg
        .append("g")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(xAxis);
    },
    updateChart() {
      this.svg.select(".mean-label").text(this.mean);

      this.svg.select(".sd-label-1").text(this.mean + this.stdDev);
      this.svg.select(".sd-label-2").text(this.mean + 2 * this.stdDev);
      this.svg.select(".sd-label-1n").text(this.mean - this.stdDev);
      this.svg.select(".sd-label-2n").text(this.mean - 2 * this.stdDev);

      if (this.stdDev && this.mean) {
        this.svg.selectAll(".sd-line").attr("stroke-opacity", 1);
        this.svg.select(".sd-label-1").attr("fill-opacity", 1);
        this.svg.select(".sd-label-2").attr("fill-opacity", 1);
        this.svg.select(".sd-label-1n").attr("fill-opacity", 1);
        this.svg.select(".sd-label-2n").attr("fill-opacity", 1);
      } else {
        this.svg.selectAll(".sd-line").attr("stroke-opacity", 0);
        this.svg.select(".sd-label-1").attr("fill-opacity", 0);
        this.svg.select(".sd-label-2").attr("fill-opacity", 0);
        this.svg.select(".sd-label-1n").attr("fill-opacity", 0);
        this.svg.select(".sd-label-2n").attr("fill-opacity", 0);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/palette.scss";

.smaller-larger {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  input {
    width: 85px;
    margin-bottom: 0;
  }

  .range-text {
    min-width: 38px;
  }
}

.chart {
  width: 100%;
  height: 100%;
  margin: 1rem auto;
  position: relative;
}

input {
  display: block;
  width: 14rem;
  border-radius: 0.375rem;
  padding: 0.375rem 0.5rem;
  border: 1px solid #b0b0b0;
  color: #4a4a4a;
  &:focus {
    outline: none;
    color: #4a4a4a;
  }
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  input {
    width: 140px;
  }

  .smaller-larger {
    input {
      width: 125px;
    }
  }
}

.math {
  font-family: consolas;
}

.info-text {
  font-style: italic;
  color: $m_gray-2;
}

:deep(svg) {
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

// hide arrows in number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
